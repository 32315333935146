const eng = () => {
    return {
        stocks: {
            found: 'Found',
            available: 'Available',
            equityUsed: 'Equity Used',
            profitLoss: 'Profit Loss',
            bid: 'Bid',
            buyButton: 'Buy',
            sellButton: 'Sell',
            margin: 'margin',
            buttonCashOut: 'CASH OUT',
            userInformation: 'BALANCE'
        }
    }
}

export default eng;