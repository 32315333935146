import styled, { css } from 'styled-components';
import Button from '@mui/material/Button';
import { MuiButtonProps } from './Button.interface';

export const StyledButton = styled(Button) <MuiButtonProps>`
    border-radius: ${props => props.borderRadius ? props.borderRadius : '8px'} !important;
    ${props => {
        if (props.width) {
            return css`width : ${props.width}`;
        };
    }};
    ${props => {
        if (props.boxShadow) {
            return css`box-shadow : ${props.boxShadow} !important`;
        };
    }};
    ${props => props}
`;