import './App.scss';
import '@mui/material/styles';
import { Route, Routes } from 'react-router-dom';
import theme from './theme';
import React, { useEffect } from 'react';
import rtlPlugin from 'stylis-plugin-rtl';
import { prefixer } from 'stylis';
import { useDispatch, useSelector } from "react-redux";
import createCache from '@emotion/cache';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { CacheProvider } from '@emotion/react';
import { useLocation, matchPath } from 'react-router';
import MainRoute from './route/Main.route';
import { ROUTES } from './constant/routes';
import { ThemeContextProvider } from './context/theme.context';
import { LabelsContextProvider } from './context/language.context';
import { getUseInfo } from './store/action';
import { useSocket } from './hooks/useSocket.hook';
import { SocketContextProvider } from './context/socket.context';
import AuthRoute from './route/Auth.route';

function App() {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const isAuthPath = matchPath('/auth/*', pathname);

    useEffect(() => {
        !isAuthPath && dispatch(getUseInfo());
    }, [])

    return (
        <CacheProvider
            value={createCache({
                key: 'muirtl',
                stylisPlugins: [prefixer, rtlPlugin],
            })}
        >
            <ThemeProvider theme={theme}>
                <ThemeContextProvider>
                    <LabelsContextProvider>
                        <SocketContextProvider>
                            <CssBaseline />
                            <div className={`App`} style={{ direction: theme.direction }}>
                                <MainRoute {...ROUTES} />
                                <AuthRoute {...ROUTES} />
                            </div>
                        </SocketContextProvider>
                    </LabelsContextProvider>
                </ThemeContextProvider>
            </ThemeProvider>
        </CacheProvider>
    )
}

export default React.memo(App)

