import React from 'react';
import * as S from './MobileActionButtons.style';
import { Labels } from '../../../languages';

const MobileActionButtons = ({buyButton, sellButton, handleBuy, handleSell, isBuy ,isSell}: MobileActionButtonsProps) => {
    return (
        <S.ActionButtonGameContainer>
            <S.SellButton onClick={handleSell}>
                <S.BlurSellButton isBuy={isSell}>
                    <S.TextButton>
                        {sellButton}
                    </S.TextButton>
                </S.BlurSellButton>
            </S.SellButton>
            <S.BuyButton onClick={handleBuy}>
                <S.BlurBuyButton isBuy={isBuy}>
                    <S.TextButton>
                        {buyButton}
                    </S.TextButton>
                </S.BlurBuyButton>
            </S.BuyButton>
        </S.ActionButtonGameContainer>
    )
}

export default MobileActionButtons;


type MobileActionButtonsProps = {
    buyButton: string; 
    sellButton: string;
    handleBuy: () => void;
    handleSell: () => void;
    isBuy: boolean;
    isSell: boolean;
}