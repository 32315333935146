// import React from "react"
// import { Route, Routes } from "react-router-dom"
// import AuthContainer from "../feature/auth/AuthContainer"
// import ForgotPassword from "../page/Auth/ForgotPassword/ForgotPassword"
// import ResetPassword from "../page/Auth/ResetPassword/ResetPassword"
// import Login from "../page/Auth/Login/Login"
// import { RoutesLabels } from "../constant"

import React from "react"
import { Route, Routes } from "react-router-dom";
import Login from "../page/auth/Login/Login.page";
import { RoutesLabels } from "../constant";

const AuthRoute = ({auth: {login}}: RoutesLabels) => {
    return (
        <Routes>
            <Route path={`/auth/${login}`} element={<><Login /></>} />
        </Routes>
    )
}

export default AuthRoute;

