export const calculateYieldValue = (initialMoney: number, currentPrice: number, futurePrice: number) => {
    const bitcoinAmount = initialMoney / currentPrice; 
    const futureValue = bitcoinAmount * futurePrice;   
    return +(futureValue.toFixed(2)); 
}

export const calculateProfitLossBuy = (initialMoney: number, yieldValue: number) => {
    return +(yieldValue - initialMoney).toFixed(2);
}

export const calculateProfitLossSell = (initialMoney: number, yieldValue: number) => {
    return +( initialMoney - yieldValue).toFixed(2);
}