import { Route, Routes } from "react-router-dom"
import { RoutesLabels } from "../constant";
import StocksMarket from "../game/StocksMarket/StocksMarket.game";
import Policy from "../page/Policy/Policy.page";

const MainRoute = ({games: {stockMarket}, policy}: RoutesLabels) => {
    return (
        <Routes>
            <Route path={`${stockMarket}`} element={<><StocksMarket /></>} />
            <Route path={`${policy}`} element={<><Policy /></>} />
        </Routes>
    )
}

export default MainRoute;