import moment from 'moment';
import { apiError } from './api';
import { LabelsReducer } from '../../constant/LabelsReducer';

export function getUseInfo(onSuccessCallBack = () => { }, onFailureCallBack = (error: any) => { }) {
    return apiAction({
        url: `/v1/user/info`,
        method: LabelsReducer.api.GET,
        onSuccess: (data: any) => {
            if (data) {
                onSuccessCallBack()
                return {
                    type: LabelsReducer.redux.SET_USER_INFO,
                    payload: data.content,
                }
            }
        },
        label: LabelsReducer.redux.GET_USER_INFO,
        onFailure: (error: any) => onFailureCallBack(error)
    })
}

export function apiAction({
    url = '',
    method = LabelsReducer.api.GET,
    data,
    accessToken = null,
    userAccount = null,
    onSuccess,
    onFailure,
    label = '',
    headers = null,
    payload = null,
    type = LabelsReducer.reducer_api_type.api,
    forece = false,
    shouldThrow = false,
}: Partial<ApiActionProps>) {
    return {
        type,
        payload,
        metaData: {
            api: {
                url,
                method,
                data,
                accessToken,
                userAccount,
                onSuccess,
                onFailure,
                label,
                headers,
                forece,
                shouldThrow
            }
        }
    }
}

type ApiActionProps = {
    url: string;
    method: 'get' | 'post' | 'put' | 'delete' | string;
    data: any | null;
    accessToken?: string | null;
    userAccount?: string | null;
    onSuccess: any;
    onFailure: any;
    label: '' | string;
    headers?: any | null;
    payload: any | null;
    type: 'API' | 'API_START' | 'API_END' | 'ACCESS_DENIED' | 'API_ERROR' | 'FRONT_END_ONLY' | string;
    forece: boolean;
    shouldThrow: boolean;
}

type TransactionSearchVal = {
    userId: number;
    value: any;
  }
