import * as S from './style';

const Coin = ({value, index, size, color, isSelected, handleSelectBid}: CoinProps) => {
    return (
        <S.CoinContainer isSelected={isSelected}>
            <S.Coin size={size} color={color} onClick={() => handleSelectBid(value || 0, index)}>
                {value}
            </S.Coin>
        </S.CoinContainer>
    )
}

export default Coin;

type CoinProps = {
    value?: number;
    index: number;
    size?: string;
    color?: string;
    isSelected: boolean;
    handleSelectBid: (value: number, index: number) => void
}