export const ROUTES = {
    home: {
        main: '/'
    },
    games: {
        stockMarket: 'stocks'
    },
    policy: 'policy',
    auth: {
        login: 'login',
        forgotPassword: 'forgot-password'
    },
}
