export const LabelsReducer = {
    redux: {
        SET_SYSTEM_SETTING: 'SET_SYSTEM_SETTING',
        GET_SYSTEM_SETTING: 'GET_SYSTEM_SETTING',
        SET_DATA_ROWS: 'SET_DATA_ROWS',
        GET_DATA_ROWS: 'GET_DATA_ROWS',
        SET_DATA_GET_ACCOUNTS: 'SET_DATA_GET_ACCOUNTS',
        GET_DATA_GET_ACCOUNTS: 'GET_DATA_GET_ACCOUNTS',
        GET_ACCOUNTS: 'GET_ACCOUNTS',
        SET_ACCOUNT: 'SET_ACCOUNT',
        GET_USER_INFO: 'GET_USER_INFO',
        SET_USER_INFO: 'SET_USER_INFO'
    },
    api: {
        GET: 'get',
        POST: 'post',
        PUT: 'put',
        DELETE: 'delete',
        PATCH: 'patch'
    },
    card: {
        GET_CARD_DATA: 'GET_CARD_DATA',
        GET_ALL_CARDS_BY_TYPE: 'GET_CARD_DATA_BY_TYPE',
        SET_ALL_CARDS_BY_TYPE: 'SET_CARD_DATA_BY_TYPE',
        SET_CREATE_NEW_CARD: 'SET_CREATE_NEW_CARD',
        GET_CREATE_NEW_CARD: 'GET_CREATE_NEW_CARD',
        SET_UPDATE_CARD: 'SET_UPDATE_CARD',
        GET_UPDATE_CARD: 'GET_UPDATE_CARD',
        SET_TEMP_CARD: 'SET_TEMP_CARD',
        SET_DELETE_CARD: 'SET_DELETE_CARD',
    },
    reducer_api_type: {
        api: 'API',
        start: 'API_START',
        end: 'API_END',
        denied: 'ACCESS_DENIED',
        error: 'API_ERROR',
        front_only: 'FRONT_END_ONLY'
    },
    itc_six_tickets: {
        GET_ITC_SIX_TICKETS: 'GET_ITC_SIX_TICKETS',
        SET_ITC_SIX_TICKETS: 'SET_ITC_SIX_TICKETS',
    },
    card_tags: {
        GET_CARD_TAGS: 'GET_CARD_TAGS',
        SET_CARD_TAGS: 'SET_CARD_TAGS',
    }
}

