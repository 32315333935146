import axios from "axios"

export const login = async ({email, password}: Login): Promise<LoginResponse | null> => {
    try {
        return await axios.post(`${getBaseDomain()}/user/login`, {email, password} /*,{withCredentials:true}*/);
    } catch (error) {     
        return null;
    }
}

export const createGest = async () => {
    try {
        return await axios.get(`${getBaseDomain()}/v1/gest/token`, {withCredentials:true});
    } catch (error) {
        return null;
    }
}

export const signIn = async ({
    display_name,
    email,
    password
}: SignIn) => {
    try {
        return await axios.post(`${getBaseDomain()}/register`,  { display_name, email, password } )
    } catch (error) {
        return null;
    }
}

export const getBaseDomain = () => {
    const {hostname, protocol, port} = window.location;
    return `${protocol}//api.${hostname.replace(/^[^.]+\.(.+)/, '$1')}${port === '3000' ? `:${4000}` : ''}`;
}

type LoginResponse = {
    data: {access_token: string}
}

export type Login = {
    email: string;
    password: string;
}

export type SignIn = {
    display_name: string;
} & Login;

