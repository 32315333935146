import { useEffect, useRef, useState } from 'react';
import { ColorType, createChart, IChartApi, IPriceLine, ISeriesApi, Time } from 'lightweight-charts';
import useIsMobile from '../../hooks/useIsMobile';
import MobileActionButtons from './MobileActionButtons/MobileActionButtons';
import { useLabelsContext } from '../../context/language.context';
import MuiTypography from '../../components/inputs/Typography/Typography';
import Coin from '../../components/inputs/Coin/Coin';
import { useDispatch, useSelector } from 'react-redux';
import { createGest } from '../../service/auth.service';
import { getCookieValue } from '../../util/coockieHelper';
import { useSocketContext } from '../../context/socket.context';
import bitcoinLogo from '../../assets/img/bitcoin_logo.png'
import { selectUser } from '../../store/selector/user.selector';
import { getUseInfo } from '../../store/action';
import { GameInfo } from '../../store/dto/store.types';
import * as S from './StocksMarket.style';
import { calculateProfitLossBuy, calculateProfitLossSell, calculateYieldValue } from '../../util/calculator.util';

const StocksMarket = ({symbol}: StocksMarketProps) => {
    const dispatch = useDispatch();
    const isMobile = useIsMobile();
    const {stocks} = useLabelsContext();
    const [coins, setCoins] = useState([...DEFAULT_COINS]);
    const [isBuy, setIsBuy] = useState<boolean>(false);
    const [isSell, setIsSell] = useState<boolean>(false);
    const totalStrength = 50 + 50;
    const leftPercentage = (50 / totalStrength) * 100;
    const rightPercentage = (50 / totalStrength) * 100;
    
    const chartContainerRef = useRef<HTMLDivElement | null>(null);
    const chartRef = useRef<IChartApi | null>(null);
    const lineSeriesRef = useRef<ISeriesApi<'Line'> | null>(null);
    const areaSeriesRef = useRef<ISeriesApi<'Area'> | null>(null);
    const priceLineRef = useRef<IPriceLine | undefined>();
    const [bidInfo, setBitInfo] = useState<BidInfo | {}>({});
    const {bitCoinSocket, apiSocket} = useSocketContext();
    const [user, setUser] = useState<Partial<GameInfo & TradeCondition>>({isCashOut: false});
    const [priceLine, setPriceLine] = useState<IPriceLine | undefined>();
    const [onlineUsers, setOnlineUsers] = useState<any[]>([]);
    const userInfo = useSelector(selectUser());
    const [currenctParice, setCurrenctParice] = useState(0)

    console.log(onlineUsers)

    useEffect(() => {
        userInfo && setUser({...userInfo})
    }, [userInfo])

    useEffect(() => {
        for(const user in onlineUsers) {
            const profitLossYield = calculateYieldValue(onlineUsers[user]?.bid || 0, onlineUsers[user]?.price || 0, currenctParice || 0);
            onlineUsers[user].profitLoss = calculateProfitLossBuy(onlineUsers[user]?.bid || 0, profitLossYield || 0)
        }
    }, [currenctParice])

    useEffect(() => {
        if (!chartContainerRef.current) return;
        // Create the chart
        chartRef.current = createChart(chartContainerRef.current, {
          width: chartContainerRef.current.clientWidth,
          height: 300,
          layout: {
            background: { type: ColorType.Solid, color: 'transparent' },
            textColor: '#e0e0e079',
          },
          grid: {
            vertLines: {
              color: '#e0e0e0',
              visible: false
            },
            horzLines: {
              color: '#e0e0e0',
              visible: false
            },
          },
          timeScale: {
            rightOffset: 0,
            fixLeftEdge: true,
            visible: false,
            borderColor: '#cccccc',
            timeVisible: true,          
            secondsVisible: true,
            borderVisible: false,
            //lockVisibleTimeRangeOnResize: true
          },
        });
        
        areaSeriesRef.current = chartRef.current.addAreaSeries({
            topColor: 'rgba(197, 0, 161, 0.6)',
            bottomColor: 'rgba(197, 0, 161, 0.0)',
            lineColor: '#690e51',
            lineWidth: 2,
        });

        //Setting size chart
        const resizeObserver = new ResizeObserver(() => {
            if (chartContainerRef.current && chartRef.current) {
                chartRef.current.resize(
                    chartContainerRef.current.clientWidth,
                    chartContainerRef.current.clientHeight
                );
                chartRef.current.timeScale().fitContent()
            }
        });

        if (chartContainerRef.current) {
            resizeObserver.observe(chartContainerRef.current);
        }

        return () => {
              chartRef.current?.remove();
        };

      }, []);

      useEffect(() => {
        if(user?.bidPrice && !user.isCashOut) {
            const priceLine = areaSeriesRef.current?.createPriceLine({
                price: user?.bidPrice || 0,
                color: '#ffffff5a', 
                lineWidth: 2,     
                lineStyle: 0,     
                axisLabelVisible: true, 
                title: actionType?.[user?.tradeType || 1] || '', 
            });
            apiSocket.emit('bid', {price: user?.bidPrice || 0, bid: user?.bid || 0, tradeType: user.tradeType});
            setPriceLine(priceLine);
        }

        bitCoinSocket.onopen = (event: Event) => {
            const msg = JSON.stringify(DEFAULT_SOCKET_MESSAGE);
            bitCoinSocket.send(msg);
        };

        bitCoinSocket.onmessage = (event: MessageEvent) => {
            const {ts, data} = JSON.parse(event.data);
            
            if(data?.[0]?.p) {
                const newPoint = {
                    time:  (ts as Time), 
                    value: +data?.[0]?.p * 1, 
                };

                tradeListing(user, newPoint);
                setCurrenctParice(newPoint?.value)
                areaSeriesRef.current?.update(newPoint);
                chartRef.current?.timeScale().scrollToRealTime();
            }
            
        };

      }, [user?.bidPrice, user?.isCashOut, user?.isBid])

      useEffect(() => {
        
        apiSocket.on('biding_users', (data) => {
            setOnlineUsers([...data])
        });
        
        apiSocket.on('create_bid', (data) => {
            setOnlineUsers([...data])
        });
        
        apiSocket.on('cache_out', (data) => {
            setOnlineUsers([...data])
        });

         apiSocket.on('connect_error', () => {
            console.log('Connection failed. Please try again.');
        });

      }, [])

    const tradeListing = (user: Partial<GameInfo & TradeCondition>, newPoint: {time: Time; value: number;}) => {
        const profitLossYield = calculateYieldValue(user?.bid || 0, user?.bidPrice || 0, newPoint?.value || 0);
        const profitLoss = user?.tradeType === ActionType.BUY ? 
            calculateProfitLossBuy(user?.bid || 0, profitLossYield || 0) :
            calculateProfitLossSell(user?.bid || 0, profitLossYield || 0);

            
        // if (user?.isBid) {
        //     setUser({
        //         ...user,
        //         profitLoss: +profitLoss || 0,
        //         isBid: false
        //     });
        // }

        if (!isNaN(profitLossYield) && !user?.isCashOut) {
            setUser({
                ...user,
                profitLoss: +profitLoss || 0,
            });
        }
    
        if (user?.isCashOut) {
            console.log('isCashOut')
            apiSocket.emit('CashOut', {available: +((user.founds || 0) + (+profitLoss || 0)).toFixed(2), founds: +((user.founds || 0) + (+profitLoss || 0)).toFixed(2)});

            setUser({
                ...user,
                profitLoss: 0,
                isCashOut: false,
                bidPrice: 0,
                bid: 0,
                available: +((user.founds || 0) + (+profitLoss || 0)).toFixed(2),
                founds: +((user.founds || 0) + (+profitLoss || 0)).toFixed(2),
            });
        }
    }



    const handleSelectBid = (value: number, index: number) => {
        const margin = (user?.margin || 0);
        const lastBid = (value + (user?.bid || 0) / margin);
        const available = (user?.available || 0) - (value || 0);
        
        const isValidateBid = handleValidateBid(value)
        const bidWithMargin = lastBid * margin;

        if(isValidateBid) {
            setUser({...user, bid: +bidWithMargin.toFixed(2), available: +available.toFixed(2) })
            coins[index] = {...coins[index], isSelected: true}
            setCoins([...coins])
        }
      }

      const handleClearBid = () => {
        setUser({...user, bid: 0})
      }

      const handleValidateBid = (bid: number): boolean => {
        const isAvailable = bid - (user?.available ?? 0) <= 0;
        const isFounded = bid - (user?.founds ?? 0) <= 0; 

        return isAvailable && isFounded;
      }

      const handleBuy = () => {
        if((user?.bid || 0 > 0) && priceLine) {
            return;
        }

        setIsBuy(true)
        setIsSell(false)

        const seriesData = areaSeriesRef?.current && areaSeriesRef?.current.data();
        const lastElement = seriesData?.[seriesData.length - 1] as {value: number, time: number};
        handleSetPrice(lastElement?.value || 0, ActionType.BUY)

      }

      const handleSell = () => {
        if((user?.bid || 0 > 0) && priceLine) {
            return;
        }
        
        setIsBuy(false)
        setIsSell(true)

        const seriesData = areaSeriesRef?.current && areaSeriesRef?.current.data();
        const lastElement = seriesData?.[seriesData.length - 1] as {value: number, time: number};
        handleSetPrice(lastElement?.value || 0, ActionType.SELL)
      }

      const handleSetPrice = (price: number, type: ActionType) => {
        setUser((prevCount) => {
            return {
                ...prevCount, 
                bidPrice: price,
                isBid: true,
                tradeType: type
            }
        })
      }   
      
      const handleCashOut = () => {

        if(!(user?.bid || 0 > 0)) {
            return;
        }

        setIsBuy(false)
        setIsSell(false)

        // chartRef.current?.timeScale().applyOptions({
        //     rightOffset: 100,
        // });

        if (areaSeriesRef.current && priceLine) {
            areaSeriesRef.current.removePriceLine(priceLine); // Remove price line
            //areaSeriesRef.current?.setData([])
            setPriceLine(undefined);
        }

        setUser({
            ...user,
            isCashOut: true
        })

      }

    return (
        <S.StockMarketContainer>
            <S.GameDisplay>
                <S.CachedOutContainer>
                    <S.ChatTitle>
                        <MuiTypography sx={{margin: 'auto', color: '#9c17cb'}}>
                            {stocks.userInformation}
                        </MuiTypography>
                        <S.AccountInfoContainer>
                            <S.AccountInfoBid>
                                {`${stocks.found}: ${user?.founds || 0}`}
                            </S.AccountInfoBid>
                            <S.AccountInfoBid>
                                {`${stocks.available}: ${user?.available || 0}`}
                            </S.AccountInfoBid>
                            <S.AccountInfoBid>
                                {`${stocks.margin}: ${marginDuplicate} * ${user?.margin || 0}`} 
                            </S.AccountInfoBid>
                            <S.AccountInfoBid>
                                {`${stocks.bid}: ${user?.bid || 0}`} 
                            </S.AccountInfoBid>
                    </S.AccountInfoContainer>
                    </S.ChatTitle>
                </S.CachedOutContainer>     
                <S.GameChartViewContainer isMobile={isMobile}>
                    <div ref={chartContainerRef} style={{ height: "60vh", width: '100%', margin: "0 auto" }} />;
                </S.GameChartViewContainer>
                <S.ChatContainer>
                    <S.ChatTitle>
                        <MuiTypography sx={{margin: 'auto', color: '#9c17cb'}}>
                            LIVE CHAT
                        </MuiTypography>
                    </S.ChatTitle>
                        {onlineUsers.map(({display_name, profitLoss}) => 
                            <S.ChatList>
                                <S.ChatContent>
                                    <MuiTypography sx={{margin: 'auto', fontSize: '15px', color: 'white'}}>
                                        {profitLoss}
                                    </MuiTypography>
                                </S.ChatContent>
                                <S.ChatPlayer>
                                    <MuiTypography sx={{margin: 'auto', fontSize: '15px', color: 'white'}}>
                                        {display_name}
                                    </MuiTypography>
                                </S.ChatPlayer>
                            </S.ChatList>    
                        )}
                </S.ChatContainer>
            </S.GameDisplay>
            <S.GameAction>
                <S.UserActionCashOut>
                    <S.AccountInfoProfitLoss2 isProfit={(user?.profitLoss || 0) > 0}>
                        {`${stocks.profitLoss}: ${user?.profitLoss || 0}`} 
                    </S.AccountInfoProfitLoss2>
                    <S.CashOutButtonContainer onClick={handleCashOut}>
                                <S.CashOutButton>
                                    {stocks.buttonCashOut}
                                </S.CashOutButton>
                    </S.CashOutButtonContainer>
                </S.UserActionCashOut>
                <S.GameActionDetails>
                    <S.StrengthBarWrapper>
                        <S.StyledLinearProgress variant="determinate" sx={{
                            height: '20px',
                            background: '#013b0e',
                            }} value={leftPercentage} />
                        <S.PercentageLabel style={{ left: 0 }}>
                            {Math.round(leftPercentage)}%
                        </S.PercentageLabel>
                        <S.PercentageLabel style={{ right: 0 }}>
                            {Math.round(rightPercentage)}%
                        </S.PercentageLabel>
                    </S.StrengthBarWrapper>
                    {!isMobile ? 
                        <S.ActionButtonGameContainer>
                            <S.BuyButton>
                                <S.BlurBuyButton>
                                    <S.TextButton>
                                        {stocks.buyButton}
                                    </S.TextButton>
                                </S.BlurBuyButton>
                            </S.BuyButton>
                            <S.SellButton>
                                <S.BlurSellButton>
                                    <S.TextButton>
                                        {stocks.sellButton}
                                    </S.TextButton>
                                </S.BlurSellButton>
                            </S.SellButton>
                        </S.ActionButtonGameContainer>
                    : 
                        <MobileActionButtons
                            {...stocks}
                            handleBuy={handleBuy}
                            handleSell={handleSell}
                            isBuy={isBuy}
                            isSell={isSell}
                        />
                    }
                    <S.CoinsContainer>
                        {coins?.map(({value, size, color, isSelected}, index) => 
                            <Coin value={value} index={index} handleSelectBid={handleSelectBid} size={size} color={color} isSelected={isSelected} />
                        )}
                    </S.CoinsContainer>
                </S.GameActionDetails>
                <S.GameActionContainer>
                    <S.SelectedSymbolContainer>
                        <img src={bitcoinLogo} alt="sdasd" width="100%" height="100%" />
                    </S.SelectedSymbolContainer>
                </S.GameActionContainer>
            </S.GameAction>
        </S.StockMarketContainer>
    )
}

export default StocksMarket;

type StocksMarketProps = {
    symbol?: string;
}

const DEFAULT_SOCKET_MESSAGE = {
    "op": "subscribe",
    "args": [
        "publicTrade.BTCUSDT",
        "liquidation.BTCUSDT"
    ]
}

const DEFAULT_COINS = [
    {value: 1, size: '40px', color: 'blue', isSelected: false},
    {value: 1000, size: '42px', color: '#c100bd', isSelected: false},
    {value: 25000, size: '50px', color: 'blue', isSelected: false},
    {value: 50000, size: '50px', color: 'green', isSelected: false},
    {value: 10000, size: '50px', color: 'red', isSelected: false},
    {value: 100, size: '42px', color: 'black', isSelected: false},
    {value: 50, size: '40px', color: '#9c17cb', isSelected: false}
]

enum ActionType {
    BUY = 1,
    SELL = 2
}

const actionType = {
    [ActionType.BUY]: 'Buy',
    [ActionType.SELL]: 'Sell'
}

type BidInfo = {
    enterPrice: number;
    totalBid: number;
}

type TradeCondition = {
    isCashOut: boolean;
    isBid: boolean;
    tradeType?: ActionType;
}


const marginDuplicate = 1
