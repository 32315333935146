import { LabelsReducer } from "../../constant/LabelsReducer";

export default function user(state: any = {}, action: any) {
    switch(action?.type) {
        case LabelsReducer.redux.SET_USER_INFO:
            return setUser(state, action);
        default:
            return state;
    }
}

const setUser = (userState: any, action: any) => {
    const { payload } = action;
    return {...userState, ...payload, isBid: false};
}