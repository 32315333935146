import styled from "styled-components";

export const ActionButtonGameContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
`;

export const BuyButton = styled.button`
    cursor: pointer;
    border: 5px solid rgb(65 62 62);
    border-right-style: none;
    font-family: "system-ui";
    font-size: 14px;
    color: rgb(255, 255, 255);
    padding: 10px 30px;
    transition: 2s;
    width: 50%;
    height: 55px;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    border-radius: 0px 0px 0px 25px;
    background: linear-gradient(51deg, rgb(0, 0, 0) 0%, rgb(0, 87, 25) 36%);
    z-index: 1;
`;

export const BlurBuyButton = styled.div<{isBuy: boolean}>`
    position: absolute;
    left: 1px;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    border: ${props => props.isBuy ? '2px solid #ffffff87' : '2px solid #ffffff38'};
    left: 20px;
    width: 120%;
    top: -3px;
    height: 115%;
    border-radius: 0px 0px 0px 20px;
    background-color: ${props => props.isBuy ? '#024C18' : '#007021'};
`;

export const SellButton = styled.button`
    cursor: pointer;
    border: 5px solid rgb(65 62 62);
    border-left-style: none;
    font-family: "system-ui";
    font-size: 14px;
    color: rgb(255, 255, 255);
    padding: 10px 30px;
    transition: 2s;
    width: 50%;
    height: 55px;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    border-radius: 0px 0px 25px;
    background: linear-gradient(303deg, rgb(0, 0, 0) 0%, rgb(176, 11, 11) 100%);
    z-index: 1;
`;

export const BlurSellButton = styled.div<{isBuy: boolean}>`
    position: absolute;
    left: 1px;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    border: ${props => props.isBuy ? '2px solid #ffffff87' : '2px solid #ffffff38'};
    left: 20px;
    width: 120%;
    top: -3px;
    height: 115%;
    border-radius: 0px 0px 20px;
    background-color: ${props => props.isBuy ? '#600c0c61' : '#ff020261'};
`;

export const TextButton = styled.div`
    font-weight: bold;
    text-shadow: 1px 1px 2px #000000;
    font-size: 15px;
`;