enum SIZE {
    SMALL = 'small',
    MEDIUM = 'medium',
    LARGE = 'large',
    STRING = 'string'
}

enum BUTTON_VARIANT {
    CONTAINED = 'contained',
    OUTLINED = 'outlined',
    TEXT = 'text'
}

enum COLOR {
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
    SUCCESS = 'success',
    ERROR = 'error',
    INFO = 'info',
    WARNING = 'warning',
    INHERIT = 'inherit'
}

enum FIELD_TYPE {
    EMAIL = 'email',
    PASSWORD = 'password',
    TEXT = 'text',
    NUMBER = 'number',
    DATE = 'date'
}

enum TEXT_VARIANT {
    OUTLINED = 'outlined',
    STANDARD = 'standard',
    FILLED = 'filled',
}

enum TEXT_SIZE {
    SMALL = 'small',
    MEDIUM = 'medium'
}

enum ARIA_LABEL {
    CLOSE = 'close',
    OPEN = 'open'
}

export const BUTTON_PROPERTY =  {
    color: COLOR,
    size: SIZE,
    variant: BUTTON_VARIANT,
}

export const SNACK_PROPERTY =  {
    color: COLOR,
    size: SIZE,
    ariaLabel: ARIA_LABEL,
}

export const CHECKBOX_PROPERTY = {
    color: COLOR,
}

export const RADIO_PROPERTY = {
    color: COLOR,
}

export const TOGGLE_PROPERTY = {
    color: COLOR,
}

export const TEXT_FIELD_PROPERTY = {
    color: COLOR,
    type: FIELD_TYPE, 
    variant: TEXT_VARIANT,
    size: TEXT_SIZE
}

export const AUTOCOMPLETE_PROPERTY = {
    color: COLOR,
    size: TEXT_SIZE
}

export const TYPOGRAPHY_FIELD_PROPERTY = {
    fontFamily: ' "Liebling Pro",   "Noto Sans Hebrew", "sans-serif"',
    variant: {
        h1: 'h1',
        h2: 'h2',
        h3: 'h3',
        h4: 'h4',
        h5: 'h5',
        h6: 'h6',
        subtitle1: 'subtitle1',
        subtitle2: 'subtitle2',
        body1: 'body1',
        body2: 'body2',
        button: 'button',
        caption: 'caption',
        overline: 'overline'
    }
}

export const GLOBAL_COLOR = {
    yellow: '#ceff6d', //success.light
    blue: '#0288D1', //info.main
    lightGray: '#fafafa', //background.default
    green: '#87CE1F', //success.main
    white: '#ffffff', //primary.contrastText
    mainText: '#263238', //secondary.light
    primaryOrange: '#F75C33', //primary.main
    red: '#FF0F0F', //custom.red
    mainGray: '#BDC8C9', //custom.mainGray
    secondGray: '#15141499', //custom.secondGray
    hoverGray: '#1514140a', //custom.hoverGray
    borderGray: '#2632383b', //custom.borderGray
    borderGraySec: '#0000001e', //custom.borderGraySec
    iconGray: '#7c7c7c', //custom.iconGray
    overlay: '#00000082', //custom.overlay
    backgroundGray: '#F5F5F5', //custom.backgroundGray
}


export const MAX_ALLOWED_CARD_TABS = 3;
export const MIN_MAIN_SCREEN_WIDTH = '1300px';

