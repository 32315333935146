import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import configStore from './store/store';
import { Provider } from 'react-redux';
import { loadState, saveState } from './service/localStorage.service'

const persistedState = loadState();
const initialState = {
    view: [],
    openCardTabs: [],
    ...persistedState
}

const store = configStore(initialState);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    // <React.StrictMode>
        <BrowserRouter>
            <Provider store={store}>
                <App />
            </Provider>
        </BrowserRouter>
    // </React.StrictMode>
)

reportWebVitals()
