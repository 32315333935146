import React, { useMemo } from "react";
import { Labels } from "../languages";
import { io, Socket } from "socket.io-client";
import { getBaseDomain } from "../service/auth.service";
import { getCookieValue } from "../util/coockieHelper";

const socketContext = React.createContext<{apiSocket: Socket, bitCoinSocket: WebSocket} | null>(null);

const SocketContextProvider = ({children}: {children: React.ReactNode}) => {
    const socket = useMemo(() => {
        return {
            apiSocket: io(`${getBaseDomain()}`, { auth: { access_token: getCookieValue('access_token') } }),
            bitCoinSocket: new WebSocket(`wss://stream.bybit.com/v5/public/linear`)//new WebSocket(`wss://data-stream.binance.vision:9443/ws`)
        }
    }, []);

    return <socketContext.Provider value={socket}>{children}</socketContext.Provider>
}

const useSocketContext = () => {
    const context = React.useContext(socketContext);

    if(!context) {
        throw("No Labels");
    }

    return context;
}

export {SocketContextProvider, useSocketContext};