const Policy = () => {
    return (
        <>
            <h1>Privacy Policy</h1>
            <p>Effective Date: <strong>12/01/2025</strong></p>
            <p>
                MarginWar respects your privacy and is committed to
                protecting your personal information. This Privacy Policy explains how we collect,
                use, and share information when you use our Android game MarginWar.
            </p>

            <div>
                <h2>1. Information We Collect</h2>
                <h3>1.1 Information You Provide</h3>
                <ul>
                <li><strong>Account Details:</strong> If you create an account, we may collect your name, email address, and profile information.</li>
                <li><strong>In-Game Purchases:</strong> Payment details are processed by third-party payment providers and are not stored by us.</li>
                </ul>
                <h3>1.2 Automatically Collected Information</h3>
                <ul>
                <li><strong>Device Information:</strong> Includes your device model, operating system version, and unique device identifiers.</li>
                <li><strong>Usage Data:</strong> Information about how you interact with the Game, such as game progress, session duration, and in-game activities.</li>
                <li><strong>Location Data:</strong> Approximate location (e.g., country or city) derived from your IP address.</li>
                </ul>
                <h3>1.3 Third-Party Information</h3>
                <p>
                We may collect data from third-party platforms you connect to the Game, such as social media accounts (if applicable).
                </p>
            </div>

            <div>
                <h2>2. How We Use Your Information</h2>
                <p>We use your information to:</p>
                <ul>
                <li>Provide and improve the Game.</li>
                <li>Process in-game purchases.</li>
                <li>Offer customer support.</li>
                <li>Monitor and analyze usage to improve gameplay and features.</li>
                <li>Personalize your experience.</li>
                <li>Communicate updates, offers, and promotions (with your consent).</li>
                </ul>
            </div>

            <div>
                <h2>3. Sharing Your Information</h2>
                <p>We may share your information with:</p>
                <ul>
                <li><strong>Service Providers:</strong> Third-party companies that help us operate the Game (e.g., cloud hosting, analytics, customer support).</li>
                <li><strong>Advertising Partners:</strong> To display relevant ads in the Game.</li>
                <li><strong>Legal Authorities:</strong> If required by law or to protect our legal rights.</li>
                </ul>
            </div>

            <div>
                <h2>4. Data Retention</h2>
                <p>
                We retain your information only for as long as necessary to fulfill the purposes outlined in this Policy or as required by law.
                </p>
            </div>

            <div>
                <h2>5. Your Rights</h2>
                <p>
                Depending on your location, you may have the following rights:
                </p>
                <ul>
                <li>Access, update, or delete your data.</li>
                <li>Restrict or object to data processing.</li>
                <li>Withdraw consent for data usage.</li>
                <li>Contact your local data protection authority.</li>
                </ul>
            </div>

            <div>
                <h2>6. Security Measures</h2>
                <p>
                We take reasonable measures to protect your information from unauthorized access, loss, or misuse. However, no system is completely secure.
                </p>
            </div>

            <div>
                <h2>7. Third-Party Links</h2>
                <p>
                The Game may include links to third-party websites or services. We are not responsible for their privacy practices. Please review their policies separately.
                </p>
            </div>

            <div>
                <h2>8. Children's Privacy</h2>
                <p>
                The Game is not intended for children under the age of <strong>[Insert Age, e.g., 13]</strong>. If we become aware that we have collected data from a child without parental consent, we will delete it.
                </p>
            </div>

            <div>
                <h2>9. Changes to This Policy</h2>
                <p>
                We may update this Privacy Policy from time to time. The "Effective Date" at the top will indicate the latest revision.
                </p>
            </div>

            <div>
                <h2>10. Contact Us</h2>
                <p>If you have any questions or concerns about this Privacy Policy, please contact us at:</p>
                <ul>
                <li><strong>Email:</strong> [Your Email Address]</li>
                <li><strong>Website:</strong> <a href="[Your Website URL]">[Your Website URL]</a></li>
                <li><strong>Address:</strong> [Your Physical Address, if applicable]</li>
                </ul>
            </div>
        </>
    )
}

export default Policy