import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import { composeWithDevTools } from '@redux-devtools/extension';
import { thunk } from 'redux-thunk';
import apiMiddleware from './middleware/api';
import user from './reducer/user.reducer';

export default function configStore(initialState: any) {
    const reducer = {
        user,
    }

    const rootReducer = combineReducers({
        ...reducer
    });

    const middleware = [apiMiddleware, thunk];

    // Will be for devtool extension
    const isDevelopment = true;//process.env.REACT_APP_MODE === 'dev';
    if (isDevelopment && typeof window !== 'undefined') {
        return createStore(rootReducer, initialState, composeWithDevTools(applyMiddleware(...middleware)))
    }

    return createStore(rootReducer, initialState, compose(applyMiddleware(...middleware)))
}
