import React from 'react';
import styled from 'styled-components';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import * as S from './Button.style';
import { MuiButtonProps } from './Button.interface';

const MuiButton: React.FC<MuiButtonProps> = ({ children, label, borderRadius, loading = false, ...props }) => {  
  return (
    <S.StyledButton 
        {...props}
        label={label}
        loading={loading}
        borderRadius={borderRadius}
        disabled={loading || props.disabled}
      >
        {children && children}
      {loading ? <CircularProgress color="inherit" size={16} /> : label}
    </S.StyledButton>
  );
};

export default MuiButton;
export type { MuiButtonProps };
