import styled from "styled-components";

export const Coin = styled.div<{size?: string, color?: string}>`
    position: relative;
    width: ${props => (props.size)};
    height: ${props => (props.size)};
    border: 3px dashed white;
    border-radius: 50%;
    line-height: 100px;
    text-align: center;
    font-family: Helvetica;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${props => (props.color)};
    font-size: 10px;

    &::before {
        position: absolute;
        z-index: -1;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: -16px;
        border-radius: 50%;
        background: red;
        /* content: ''; */
    }

    &::after {
        position: absolute;
        z-index: 0;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: 5px;
        border: 1px dashed white;
        border-radius: 50%;
        background: rgba(0,0,0,0.4);
        content: '';
    }
`;

export const CoinContainer = styled.div<{isSelected: boolean}>`
    border: ${props => (props.isSelected ? '2px solid #cfcfcf' : '')}; 
    border-radius: 50%;
`;

export const CoinNumber = styled.div`
    font-size: 12px;
    color: white;
`;