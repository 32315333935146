import { useNavigate } from "react-router-dom";
import MuiButton from "../../../components/inputs/Button/Button";
import { BUTTON_PROPERTY } from "../../../constant/ComponentsProperty";
import { createGest } from "../../../service/auth.service";
import * as S from './login.style'

const Login = () => {
    const navigate = useNavigate();

    const initializeUserInfo = async () => {
        const isGest = await createGest()
        if(isGest) {
            navigate('/stocks')
            navigate(0)
        }
    }

    return (
        <S.LoginContainer>
            <MuiButton
                onClick={initializeUserInfo}
                size={BUTTON_PROPERTY.size.SMALL}
                color={BUTTON_PROPERTY.color.INFO}
            >
                Login
            </MuiButton>
        </S.LoginContainer>
    )
}

export default Login;